/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from 'ethers'
import type { Provider } from '@ethersproject/providers'
import type {
  Multi_send,
  Multi_sendInterface,
} from '../../../../../../@safe-global/safe-deployments/dist/assets/v1.3.0/Multi_send'

const _abi = [
  {
    inputs: [],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    inputs: [
      {
        internalType: 'bytes',
        name: 'transactions',
        type: 'bytes',
      },
    ],
    name: 'multiSend',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
] as const

export class Multi_send__factory {
  static readonly abi = _abi
  static createInterface(): Multi_sendInterface {
    return new utils.Interface(_abi) as Multi_sendInterface
  }
  static connect(address: string, signerOrProvider: Signer | Provider): Multi_send {
    return new Contract(address, _abi, signerOrProvider) as Multi_send
  }
}
