/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from 'ethers'
import type { Provider } from '@ethersproject/providers'
import type {
  Simulate_tx_accessor,
  Simulate_tx_accessorInterface,
} from '../../../../../../@safe-global/safe-deployments/dist/assets/v1.4.0/Simulate_tx_accessor'

const _abi = [
  {
    inputs: [],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'to',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'value',
        type: 'uint256',
      },
      {
        internalType: 'bytes',
        name: 'data',
        type: 'bytes',
      },
      {
        internalType: 'enum Enum.Operation',
        name: 'operation',
        type: 'uint8',
      },
    ],
    name: 'simulate',
    outputs: [
      {
        internalType: 'uint256',
        name: 'estimate',
        type: 'uint256',
      },
      {
        internalType: 'bool',
        name: 'success',
        type: 'bool',
      },
      {
        internalType: 'bytes',
        name: 'returnData',
        type: 'bytes',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
] as const

export class Simulate_tx_accessor__factory {
  static readonly abi = _abi
  static createInterface(): Simulate_tx_accessorInterface {
    return new utils.Interface(_abi) as Simulate_tx_accessorInterface
  }
  static connect(address: string, signerOrProvider: Signer | Provider): Simulate_tx_accessor {
    return new Contract(address, _abi, signerOrProvider) as Simulate_tx_accessor
  }
}
